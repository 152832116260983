import { addHours, isBefore } from "date-fns";

export const DEFAULT_EXPIRATION_INTERVAL_HOURS = 168;

export const shouldLogout = (decodedToken: any, signInDate: Date): boolean => {
  // Look up for the new property (added by Cloud Function)
    if (decodedToken.claims?.expire_time) {
        return !isLoginRecent(decodedToken.claims.expire_time);
    }

    // Look up for the previous property (also added by Cloud Function)
    if (decodedToken.claims?.expiration_interval) {
        return !isLoginRecent_deprecated(signInDate, decodedToken.claims.expiration_interval);
    }

    // If none was found, use the default expiration interval (in hours)
    return !isLoginRecent_deprecated(signInDate, DEFAULT_EXPIRATION_INTERVAL_HOURS);
};

/**
 * @deprecated This method should not be used anymore.
 */
const isLoginRecent_deprecated = (signInDate: Date, expirationInterval: number): boolean => {
    const expirationDate = addHours(signInDate, expirationInterval);
    return isBefore(new Date(), expirationDate);
};

const isLoginRecent = (authTime: number): boolean => {
    return authTime > Date.now();
};
