<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="mat-dialog-header">
    <span class="mat-dialog-header-title">Scanner de QR Code</span>
    <button mat-icon-button color="primary" (click)="close()" class="mat-icon-button small-circle-button">
        <mat-icon class="mat-icon">close</mat-icon>
    </button>
</div>
<mat-dialog-content class="qr-code mat-dialog-content">
  <div class="dialog-text mb-16">Digite o código para validar o QR code</div>

  <div class="dialog-row">
    <mat-form-field color="primary" appearance="outline" required class="mat-form-field">
        <mat-label>Código</mat-label>
        <input matInput placeholder="Código" [formControl]="secret" />
        <mat-error *ngIf="secret.hasError('required')">
          Campo obrigatório
        </mat-error>
        <mat-error *ngIf="secret.hasError('wrongSecret')">
          Código incorreto
        </mat-error>
        <mat-error *ngIf="secret.hasError('downloadError')">
          Não foi possível fazer dowload do PDF original
        </mat-error>
        <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
    </mat-form-field>
    <button mat-button class="icon-button-with-loading" (click)="submit()" [disabled]="loading">
        <mat-icon *ngIf="!loading">check</mat-icon>
        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
    </button>
  </div>

  <div class="dialog-row">
    <div class="dialog-link">
      <div>Ou valide manualmente</div>
      <div class="link-preview" (mousedown)="stopIt($event)">{{ data.qrCodeLink }}</div>
    </div>
    <button mat-button color=primary class="link-button" (click)="copyLinkToClipboard(data.qrCodeLink)">
      <mat-icon>content_copy</mat-icon>Copiar link
    </button>
  </div>
</mat-dialog-content>
