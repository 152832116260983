<div #container class="img-container">
  <img
    *appRecreateViewKey="src"
    appNgxViewer
    [viewerOptions]="viewerOptions"
		#imageElement
    alt="Imagem do documento"
		[src]="src"
    (viewerZoomed)="setInitialZoom($event?.detail?.oldRatio)"
    (load)="imageLoaded()">
</div>
