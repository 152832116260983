import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxViewerDirective } from '../ngx-viewer/ngx-viewer.directive';

@Component({
  selector: 'app-image-preview',
  templateUrl: 'image-preview.component.html',
  styleUrls: ['image-preview.component.scss'],
})
export class ImagePreviewComponent {
  src: SafeResourceUrl;
  style: {[key: string]: string};
  public viewerOptions: any = {
    navbar: false,
    fullscreen: false,
    focus: false,
    inline: true,
    backdrop: false,
    button: false,
    zoomOnTouch: false,
    zoomOnWheel: true,
    transition: false,
    movable: true,
    keyboard: false,
    loop: false,
    slideOnTouch: false,
    title: false,
    toolbar: null
  };

  private initialZoom: number;
  private previousZoom = 1;
  private _rotation: number;

  @ViewChild(NgxViewerDirective) appNgxViewer: NgxViewerDirective;

  constructor(private domSanitizer: DomSanitizer) {}

  @Input() set zoom(ratio: number) {
    if (this.appNgxViewer) {
      if (ratio === 1) {
        this.appNgxViewer.zoom(1).center();
      } else {
        this.appNgxViewer.zoom(ratio - this.previousZoom);
      }
      this.previousZoom = ratio;
    }
  }

  @Input() public set fileSrc(fileSrc: string) {
    if (!!fileSrc) {
      this.src = this.domSanitizer.bypassSecurityTrustResourceUrl(fileSrc);
    }
  }

  @Input() public set fileData(fileData: Uint8Array) {
    if (!!fileData) {
      this.src = this.domSanitizer.bypassSecurityTrustResourceUrl(
        URL.createObjectURL(new Blob([fileData]))
      );
    }
  }
  @Input() set rotation(rotation: number) {
    this._rotation = rotation;
    if (this.appNgxViewer) {
      this.appNgxViewer.rotate(this._rotation);
    }
  }

  @Output() loaded = new EventEmitter<void>();

  imageLoaded(): void {
    this.loaded.emit();
  }

  setInitialZoom(oldRatio: number | undefined) {
    if (!this.initialZoom) {
      this.initialZoom = oldRatio;
    }
  }
}
