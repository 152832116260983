import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import Viewer from 'viewerjs';
import ZoomEvent = Viewer.ZoomEvent;
import MovedEvent = Viewer.MovedEvent;

@Directive({
  selector: '[appNgxViewer]'
})
export class NgxViewerDirective implements AfterViewInit, OnDestroy {
  originalZoom: number;

  @Input() viewerOptions: Viewer.Options = {};

  @Output() viewerReady: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerShow: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerShown: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerHide: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerHidden: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerView: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerViewed: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerMoved: EventEmitter<MovedEvent> = new EventEmitter<MovedEvent>();
  @Output() viewerZoom: EventEmitter<ZoomEvent> = new EventEmitter<ZoomEvent>();
  @Output() viewerZoomed: EventEmitter<ZoomEvent> = new EventEmitter<ZoomEvent>();

  instance: Viewer;

  private nativeElement: HTMLElement;

  constructor(private elementRef: ElementRef) {
    this.nativeElement = this.elementRef.nativeElement;
  }

  public ngAfterViewInit(): void {
    this.initViewer();
  }

  private initViewer(): void {
    if (this.instance) {
      this.instance.destroy();
    }

    this.instance = new Viewer(this.nativeElement, {
      // Transitions currently break the Viewer when running optimizations during ng build (i.e in prod mode)
      // TODO: Find a fix for this so we don't have to force disable transitions
      transition: false,
      ...this.viewerOptions
    });

    this.nativeElement.addEventListener('ready', event => this.viewerReady.emit(event), false);
    this.nativeElement.addEventListener('show', event => this.viewerShow.emit(event), false);
    this.nativeElement.addEventListener('shown', event => this.viewerShown.emit(event), false);
    this.nativeElement.addEventListener('hide', event => this.viewerHide.emit(event), false);
    this.nativeElement.addEventListener('hidden', event => this.viewerHidden.emit(event), false);
    this.nativeElement.addEventListener('view', event => this.viewerView.emit(event), false);
    this.nativeElement.addEventListener('viewed', event => this.viewerViewed.emit(event), false);
    this.nativeElement.addEventListener('moved', (event: MovedEvent) => this.viewerMoved.emit(event), false);
    this.nativeElement.addEventListener('zoom', (event: ZoomEvent) => this.viewerZoom.emit(event), false);

    this.nativeElement.addEventListener('zoomed', (event: ZoomEvent) => {
      if (!this.originalZoom) {
        this.originalZoom = event?.detail?.oldRatio;
      }
      this.viewerZoomed.emit(event);
    }, false);
  }

  public zoom(ratio: number): NgxViewerDirective {
    if (this.instance) {
      if (ratio === 1) {
        this.instance.zoomTo(this.originalZoom);
      } else {
        this.instance.zoom(ratio, true);
      }
    }
    return this;
  }

  public rotate(degree: number): NgxViewerDirective {
    this.instance.rotateTo(degree);
    return this;
  }

  public center() {
    this.instance.moveTo(0);
  }

  public ngOnDestroy(): void {
    if (this.instance) {
      this.instance.destroy();
    }
  }
}
