import { CdkDrag } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { QrCodeSecretDialogComponent } from './qr-code-secret-dialog.component';

@NgModule({
  declarations: [
    QrCodeSecretDialogComponent
  ],
  exports: [
    QrCodeSecretDialogComponent
  ],
  imports: [
    CdkDrag,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyInputModule,
    MatLegacyDialogModule,
    MatLegacyDialogModule,
    MatLegacyProgressSpinnerModule,
    MatLegacyButtonModule,
    MatIconModule,
  ]
})
export class QrCodeSecretDialogModule { }
