import { Injectable } from "@angular/core";
import { QrCodeService } from "./qr-code.service";
import { SignaturesService } from "./signatures.service";
import { catchError, map, mergeMap, Observable, of } from "rxjs";

@Injectable({ providedIn: 'root' })
export class PdfService {
  constructor(
    private qrCodeService: QrCodeService,
    private signaturesService: SignaturesService,
  ) {}

  downloadAndValidate(url: string, secret: string): Observable<DownloadAndValidationResponse> {
    return this.qrCodeService.downloadPdf(url, secret).pipe(
      mergeMap((downloadResponse) => {
        const signatureFile = downloadResponse.prescription.signatureFiles[0];
        return this.signaturesService.validade(signatureFile.url).pipe(
          map((validationResponse) => {
            return {
              url: signatureFile.url,
              status: validationResponse.status,
            };
          }),
          catchError((error) => {
            throw new ValidationError();
          }),
        );
      }),
      catchError((error) => {
        if (error.status === 401) {
          throw new InvalidSecretError();
        }
        throw new DownloadError();
      }),
    );
  }
}

export class DownloadAndValidationResponse {
  url: string;
  status: string;
}

export class CustomError extends Error {
  code: number;

  constructor(message: string, code: number) {
    super(message);
    this.code = code;
    this.name = this.constructor.name;
  }
}

export class InvalidSecretError extends CustomError {
  constructor(message: string = 'error: invalid secret') {
    super(message, ErrorCodes.INVALID_SECRET);
  }
}

export class DownloadError extends CustomError {
  constructor(message: string = 'error: it was not possible to download original PDF') {
    super(message, ErrorCodes.DOWNLOAD_ERROR);
  }
}

export class ValidationError extends CustomError {
  constructor(message: string = 'error: it was not possible to validate original PDF') {
    super(message, ErrorCodes.VALIDATION_ERROR);
  }
}

export enum ErrorCodes {
  INVALID_SECRET = 1,
  DOWNLOAD_ERROR = 2,
  VALIDATION_ERROR = 3,
}
