import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { Company, CompanyV2Service, Provider } from '../services/company-v2.service';
import { CustomTokenService } from '../services/custom-token.service';
import { UserService } from '../services/user.service';
import { Permissions, User } from '../shared/model/user';
import { shouldLogout } from '../shared/utils/auth.utils';
import { ApiService, USER_PERMISSIONS_API } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public token$: Observable<string>;
  public authUser$: Observable<firebase.User>;
  public isLoggedIn$ = new Observable<boolean>();

  public loggedInUser$: Observable<User> = new Observable<User>();
  public companyUser: User;

  public permissions$: Observable<Permissions>;
  public permissions: Permissions = new Permissions();

  public currentCompanyId: string;
  private _currentCompany$: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);
  public currentCompany$: Observable<Company> = this._currentCompany$.asObservable();
  public readonly currentCompanyId$ = this._currentCompany$.asObservable()
    .pipe(
      distinctUntilChanged((a, b) => a?.id === b?.id),
      map((company) => company?.id)
    );

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private userService: UserService,
    private companyV2Service: CompanyV2Service,
    private customTokenService: CustomTokenService,
    private router: Router,
  ) {
    this.token$ = this.currentCompanyId$.pipe(
      mergeMap((currentCompanyId) => {
        return this.authService.getUser(currentCompanyId).pipe(
          mergeMap((user) => {
            if (!user) {
              return of(null);
            }

            return from(user.getIdTokenResult()).pipe(
              mergeMap((token) => {
                if (shouldLogout(token, new Date(user.metadata.lastSignInTime))) {
                  console.warn('Old credentials. Will logout');
                  this.authService.partialLogout().then(() => {
                    this.router.navigate(['/companies']);
                  });
                }

                return this.authService.getToken(currentCompanyId || undefined);
              })
            );
          })
        );
      }),
    );

    this.authUser$ = this.currentCompanyId$.pipe(
      mergeMap((currentCompanyId) => this.authService.getUser(currentCompanyId)),
    );

    this.isLoggedIn$ = this.authUser$.pipe(
      map((user) => !!user && !user.isAnonymous)
    );

    this.permissions$ = this.currentCompany$.pipe(
      map(() => this.permissions)
    );

    this.currentCompany$.pipe(
      filter((company) => !!company),
      mergeMap((company) => this.userService.getCompleteProfile(company.id))
    ).subscribe((user) => this.companyUser = user);

    this.loggedInUser$ = this.authUser$.pipe(
      mergeMap((firebaseUser) => {
        const getUser = firebaseUser ? this.userService.getProfile() : of(null);
        return combineLatest([of(firebaseUser), getUser]);
      }),
      map(([firebaseUser, user]: [firebase.User, User]) => {
        if (user) {
          user.last_sign_in = firebaseUser.metadata.lastSignInTime;
        }
        return user;
      }),
      shareReplay(1)
    );
  }

  changeCurrentCompany(companyId?: string): Observable<boolean> {
    if (companyId === this.currentCompanyId) {
      this.currentCompanyId = companyId;
      return of(true);
    }
    return this.authService.isLoggedIn(companyId).pipe(
      take(1),
      mergeMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.updateCompanyData(companyId);
        }

        return this.automaticallySignin(companyId);
      }),
      tap(() => this.currentCompanyId = companyId)
    );
  }

  private updateCompanyData(companyId: string): Observable<boolean> {
    if (!companyId) {
      this.permissions = new Permissions();
      this._currentCompany$.next(null);
      return of(true);
    }
    return forkJoin([
      this.companyV2Service.getCompanyDetails(companyId),
      this.api.get<Permissions>(USER_PERMISSIONS_API('company', companyId))
    ])
      .pipe(
        map(([company, permissions]) => {
          this.permissions = permissions;
          this._currentCompany$.next(company);
          return true;
        }),
        catchError((err) => {
          console.error(err);
          return of(false);
        })
      );
  }

  private customTokenSignin(company: Company): Observable<boolean> {
    return this.customTokenService.create(this.currentCompanyId, company.organization_id).pipe(
      mergeMap((response) => {
        return this.authService.signInWithCustomToken(company.id, company.providers[0].auth_tenant_id, response.token).pipe(
          map((result) => !!result.user),
          catchError((error) => {
            console.error(error);
            return of(false);
          }),
        );
      })
    );
  }

  private samlSignin(companyId: string, provider: Provider): Observable<boolean> {
    return this.authService.signInWithRedirect(companyId, provider.auth_tenant_id, provider.id).pipe(
      map(() => true),
      catchError((error) => {
        console.error(error);
        return of(false);
      })
    )
  }

  private automaticallySignin(companyId: string): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      mergeMap((isLoggedIn) => {
        if (isLoggedIn) {
          // We must use default credentials to list companies
          this.updateCompanyData(null);
        }

        return this.companyV2Service.listCompanies().pipe(
          mergeMap((companies) => {
            const company = companies.items.find((company) => company.id === companyId);
            if (!company) {
              return of(false);
            }

            return this.authUser$.pipe(
              mergeMap((authUser) => {
                if (/@closecare.com.br$/.test(authUser.email)) {
                  return this.customTokenSignin(company);
                }

                const domain = authUser.email.split('@')[1];
                const provider = company.providers.find((provider) => provider.domains.includes(domain));
                if (!provider) {
                  return of(false);
                }

                if (provider.type === 'saml') {
                  return this.samlSignin(companyId, provider);
                }

                return this.customTokenSignin(company);
              }),
              mergeMap(() => this.updateCompanyData(companyId))
            );
          })
        );
      })
    );
  }
}
