import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ValidationRetryDialogComponent } from './validation-retry-dialog.component';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    ValidationRetryDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    MatLegacyInputModule,
    MatLegacyChipsModule,
    MatProgressSpinnerModule,
  ],
  exports: [ValidationRetryDialogComponent]
})
export class ValidationRetryDialogModule {}
