import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class LocalStorageService {
  getArray(key: string): string[] {
    // bootstrap
    const array = localStorage.getItem(key);
    if (!array) {
      this.setArray(key, []);
      return [];
    }
    return JSON.parse(array);
  }

  setArray(key: string, array: string[]): void {
    localStorage.setItem(key, JSON.stringify(array));
  }

  addItemToArray(key: string, item: string): void {
    const array = this.getArray(key);
    if (!array.includes(item)) {
      array.push(item);
      this.setArray(key, array);
    }
  }

  deleteArray(key: string): void {
    localStorage.removeItem(key);
  }
}

export enum LocalStorageKeys {
  FIREBASE_APP_INSTANCES_NAMES = 'firebase-app-instances-names',
}
