import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogConfig, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { PdfService } from 'src/app/services/pdf.service';

@Component({
  selector: 'app-validation-retry-dialog',
  templateUrl: './validation-retry-dialog.component.html',
  styleUrls: ['./validation-retry-dialog.component.scss']
})
export class ValidationRetryDialogComponent {
  loading: boolean = false;

  public static open(dialog: MatLegacyDialog, data: ValidationRetryDialogData, config?: MatLegacyDialogConfig): MatLegacyDialogRef<ValidationRetryDialogComponent, ValidationRetryDialogResult> {
    const c: MatLegacyDialogConfig = config || {};
    c.panelClass = 'simple-dialog';
    c.maxWidth = 'calc(100vw - 32px)';
    c.data = data;
    return dialog.open(ValidationRetryDialogComponent, c);
  }

  constructor(
    public dialogRef: MatLegacyDialogRef<ValidationRetryDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: ValidationRetryDialogData,
    private pdfService: PdfService,
  ) {}

  onOkClick() {
    this.loading = true;
    this.pdfService.downloadAndValidate(this.data.url, this.data.secret).subscribe({
      next: (result) => {
        this.dialogRef.close({
          url: result.url,
          status: result.status,
        })
      },
      error: () => {
        this.loading = false;
      }
    })
  }

  onNoClicked() {
    this.dialogRef.close(null);
  }
}

export interface ValidationRetryDialogData {
  title: string;
  titleStyle?: {[key: string]: string | number};
  content?: string;
  contentStyle?: {[key: string]: string | number};
  noButton?: string;
  yesButton?: string;
  color?: 'primary' | 'accent';
  url: string;
  secret: string;
}

export interface ValidationRetryDialogResult {
  url: string;
  status: string;
}
