import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilePreviewComponent} from './file-preview.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {ImagePreviewModule} from '../image-preview/image-preview.module';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import { SignaturePopoverModule } from '../signature-popover/signature-popover.module';
import { ValidationRetryDialogModule } from '../validation-retry-dialog/validation-retry-dialog.module';
import { StatefulLoadingModule } from '../stateful-loading/stateful-loading.module';

@NgModule({
  declarations: [FilePreviewComponent],
  exports: [
    FilePreviewComponent
  ],
  imports: [
    CommonModule,
    PdfViewerModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ImagePreviewModule,
    MatProgressSpinnerModule,
    SignaturePopoverModule,
    ValidationRetryDialogModule,
    StatefulLoadingModule,
  ]
})
export class FilePreviewModule { }
