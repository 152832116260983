import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isLoggedInIntoThePlatform().pipe(
      map(
        (isLoggedInIntoThePlatform) => {
          if (!isLoggedInIntoThePlatform) {
            return this.router.createUrlTree(['login'], { queryParams: { returnUrl: state.url }});
          }
          return true;
        }
      )
    );
  }
}
