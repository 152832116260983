import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatefulLoadingComponent } from './stateful-loading.component';
import {SpinnerModule} from '../spinner/spinner.module';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';



@NgModule({
  declarations: [StatefulLoadingComponent],
    imports: [
        CommonModule,
        SpinnerModule,
        MatButtonModule
    ],
  exports: [StatefulLoadingComponent]
})
export class StatefulLoadingModule { }
