import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxViewerDirective } from './ngx-viewer.directive';



@NgModule({
  declarations: [
    NgxViewerDirective
  ],
  exports: [
    NgxViewerDirective
  ],
  imports: [
    CommonModule
  ]
})
export class NgxViewerModule { }
