import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CUSTOM_TOKEN_API } from "../core/api.service";

export class CustomToken {
    token: string;
}

@Injectable({ providedIn: 'root' })
export class CustomTokenService {
    constructor(private httpClient: HttpClient) {}

    create(currentCompanyId: string, organizationId: number) {
        const options = currentCompanyId ? {} : { headers: { 'CC-Organization-Id': organizationId.toString() }};
        return this.httpClient.post<any>(
            CUSTOM_TOKEN_API(organizationId),
            {},
            options,
        );
    }
}
