import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { SignaturesService } from 'src/app/services/signatures.service';
import { FileComparisonDialogInputData, FilePreview } from './file-comparison-dialog.entities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SignatureStatus } from '../signature-popover/signature-popover.entities';

@Component({
  selector: 'app-file-comparison-dialog',
  templateUrl: './file-comparison-dialog.component.html',
  styleUrls: ['./file-comparison-dialog.component.scss']
})
export class FileComparisonDialogComponent {
  originalFile: FilePreview;
  downloadedFile: FilePreview;
  status: string = null;
  answers: FormGroup;
  requirementsAreMet: boolean = null;

  constructor(
    private dialogRef: MatLegacyDialogRef<FileComparisonDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: FileComparisonDialogInputData,
    private fb: FormBuilder
  ) {
    this.originalFile = data.originalFile;
    this.downloadedFile = data.downloadedFile;
    this.status = data.status;

    this.answers = this.fb.group({
      validDigitalSignature: [this.status === SignatureStatus.VALID],
      sameName: [null],
      sameIssuerData: [null],
      sameLeaveDuration: [null],
      requiredDigitalSignature: [null],
    }),

    this.answers.valueChanges.subscribe(
      (values) => {
        if (values.validDigitalSignature) {
          // requiredDigitalSignature can be ignored when validDigitalSignature
          this.requirementsAreMet = values.sameName && values.sameIssuerData && values.sameLeaveDuration
        } else {
          if (values.requiredDigitalSignature) {
            this.requirementsAreMet = false;
          } else {
            this.requirementsAreMet = values.sameName && values.sameIssuerData && values.sameLeaveDuration
          }
        }
      }
    );
  }

  close() {
    this.dialogRef.close();
  }
}
