import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { saveAs } from 'file-saver';
import { NGXLogger } from 'ngx-logger';
import { SimpleDialogComponent } from 'src/app/components/simple-dialog/simple-dialog.component';
import { Analytics } from 'src/app/services/analytics';
import { PdfService } from 'src/app/services/pdf.service';
import { QrCodeService } from 'src/app/services/qr-code.service';
import * as File from 'src/app/shared/model/file.model';
import { FileComparisonDialogComponent } from '../file-comparison-dialog/file-comparison-dialog.component';
import { QrCodeSecretDialogComponent } from '../qr-code-secret-dialog/qr-code-secret-dialog.component';
import { StatefulLoadingComponent } from '../stateful-loading/stateful-loading.component';
import { ValidationRetryDialogComponent } from '../validation-retry-dialog/validation-retry-dialog.component';
import { QR_CODE_NOT_FOUND } from 'src/app/shared/constants/errors.constants';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent {
  zoom = 1.0;
  fileContents: {
    password?: string;
    url?: string;
    data?: Uint8Array;
  };
  loading = true;
  errorMessage: string;
  focused = false;
  rotation = 0;
  qrCodeDialogIsOpen: boolean = false;

  @Input()
  status: string;

  @Input()
  showSignaturePopover: boolean = true;

  @Input()
  showQrCodeButton: boolean = true;

  private _fileName: string;
  private _fileControl: File.Control;
  private _fileType: string;
  private _pdfPassword: string;

  constructor(
    private logger: NGXLogger,
    private dialog: MatLegacyDialog,
    private analytics: Analytics,
    private qrCodeSecretDialog: MatLegacyDialog,
    private fileComparisonDialog: MatLegacyDialog,
    private qrCodeService: QrCodeService,
    private pdfService: PdfService,
    private qrCodeLoadingDialog: MatLegacyDialog,
  ) {}

  @Input() showDelete: boolean;
  @Input() set pdfPassword(pwd: string) {
    this._pdfPassword = pwd;
    this.fileContents = {
      url: this.fileContents?.url,
      password: pwd
    };
  }

  @Input() set fileSrc(fileSrc: string) {
    this.startLoad();
    this.resetFile(!!fileSrc);

    if (!!fileSrc) {
      this.fileContents = {
        url: fileSrc,
        password: this._pdfPassword
      };
    }
  }

  @Input() set fileInputed(fileInputed: File) {
    this.startLoad();
    this.resetFile(!!fileInputed);

    if (!!fileInputed) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.fileContents = {
          data: new Uint8Array(reader.result as ArrayBuffer),
          password: this.pdfPassword
        };
      }, false);

      reader.readAsArrayBuffer(fileInputed);
    }
  }

  @Input() set fileName(fileName: string) {
    this._fileName = fileName;
  }

  @Input() set fileControl(fileControl: File.Control) {
    this._fileControl = fileControl;
  }

  @Input() set fileType(fileType: string) {
    this._fileType = fileType;
  }

  @Output() delete = new EventEmitter<number>();

  get fileName(): string {
    return this._fileName;
  }

  get fileType(): string {
    return this._fileType;
  }

  get fileControl(): File.Control {
    return this._fileControl;
  }

  scanQrCode() {
    StatefulLoadingComponent.open(this.qrCodeLoadingDialog, {
      title: 'Por favor, aguarde.',
      content: 'Estamos lendo o QR code.',
    },
    {
      width: '450px',
      disableClose: true,
    });

    if (this.fileContents?.url) {
      const parts = this.fileContents.url.split('/');
      if (parts.length > 6) {
        const bucket = parts[3];
        const companyId = parts[4];
        const sickNote = parts[5];
        const fileName_ = parts[6];
        const fileName = fileName_.includes('?') ? fileName_.split('?')[0] : fileName_;
        const path = [companyId, sickNote, fileName].join('/');
        this.qrCodeDialogIsOpen = true;
        this.qrCodeService.findQrCode(bucket, path).subscribe({
          next: (response) => {
            this.qrCodeLoadingDialog.closeAll();
            const dialogRef = this.qrCodeSecretDialog.open(QrCodeSecretDialogComponent, {
              data: {
                originalImageUrl: this.fileContents.url,
                qrCodeLink: response.link,
              },
              width: '340px',
              hasBackdrop: false,
              panelClass: 'qr-code-container'
            });

            dialogRef.afterClosed().subscribe(
              (dialogData) => {
                this.qrCodeDialogIsOpen = false;
                if (dialogData.url && dialogData.status) {
                  this.openFileComparisonDialog(dialogData.url, dialogData.status);
                } else {
                  ValidationRetryDialogComponent.open(
                    this.dialog,
                    {
                      title: 'Algo deu errado!',
                      titleStyle: { 'color': '#D80000' },
                      content: 'Ocorreu um erro inesperado. Por favor, tente realizar a ação novamente.',
                      yesButton: 'TENTAR NOVAMENTE',
                      noButton: 'CANCELAR',
                      url: response.link,
                      secret: dialogData.secret,
                    },
                    {
                      width: '450px',
                      panelClass: 'download-or-validate-error',
                    }).afterClosed().subscribe(((result) => {
                      if (result) {
                        this.openFileComparisonDialog(result.url, result.status);
                      }
                    })
                  );
                }
              }
            );
          },
          error: (error) => {
            this.qrCodeLoadingDialog.closeAll();
            let title: string;
            let content: string;
            if (error.status === QR_CODE_NOT_FOUND) {
              title = 'Não foi possível ler o QR Code!'
              content = 'Verifique se o documento possui um QRcode e certifique-se de que nenhuma parte dele esteja cortada ou danificada.'
            } else {
              title = 'Algo deu errado!'
              content = 'Ocorreu um erro inesperado. Por favor, tente realizar a ação novamente.'
            }
            SimpleDialogComponent.open(
              this.dialog,
              {
                title,
                content,
                titleStyle: { 'color': '#D80000' },
                noButton: 'FECHAR',
              },
              {
                width: '450px',
              }
            );
            this.qrCodeDialogIsOpen = false;
          }
        })
      }
    }
  }

  openFileComparisonDialog(url: string, status: string) {
    this.fileComparisonDialog.open(FileComparisonDialogComponent, {
      data: {
        originalFile: {
          url: this.fileContents?.url,
          name: this._fileName,
          type: this._fileType,
        },
        downloadedFile: {
          url,
          name: this._fileName,
          type: 'application/pdf',
        },
        status,
      },
      backdropClass: 'file-comparison-dialog-backdrop',
      panelClass: 'file-comparison-dialog-panel',
      maxWidth: '100vw',
      maxHeight: '100vh',
    });
  }

  download() {
    SimpleDialogComponent.open(
      this.dialog,
      {
        title: 'Baixar arquivo',
        content: 'Ao baixar o arquivo para o seu computador, você aumenta o risco de exposição de dados sensíveis. Deseja continuar?',
        yesButton: 'Sim, baixar',
        noButton: 'Cancelar',
      },
      {
        width: '500px'
      }).afterClosed().subscribe(((result) => {
      if (result?.action) {
        saveAs(this.fileContents.url, `${this.fileName}.${this.fileType.split('/')[1]}`);
      }
    }));
  }

  zoomIn() {
    this.zoom = Math.min(5.0, this.zoom + 0.10);
  }

  zoomOut() {
    this.zoom = Math.max(1.0, this.zoom - 0.10);
  }

  defaultZoom() {
    this.zoom = 1.0;
  }

  rotate(): void {
    this.rotation = (this.rotation + 90) % 360;
  }

  errorOnLoad(error): void {
    const message = `Error loading pdf: ${error.message}`;
    console.error(error);

    this.loading = false;
    if (error?.name === 'PasswordException') {
      this.errorMessage = 'O PDF está protegido com senha e não pode ser exibido. Solicite para o colaborador reenviar um arquivo sem senha.';
      // TODO try to get password?
    } else if (!error?.message?.includes('Worker was destroyed')) {
      this.errorMessage = 'Ocorreu um erro ao visualizar o arquivo. Tente atualizar a página ou fale com o suporte no botão de ajuda.';
      this.logger.error(error);
      this.analytics.error(this.fileContents.url, message);
    }
  }

  private startLoad(): void {
    delete this.errorMessage;

    this.loading = true;
  }

  private resetFile(hasFiles: boolean): void {
    if (hasFiles) {
      this.rotation = 0;
      this.zoom = 1;
    }
  }
}

