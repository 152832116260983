<mat-sidenav-container class="full-width full-height">
  <mat-sidenav
    #sidenavMenu
    class="d-flex flex-column drawer"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(sessionService.isLoggedIn$ | async) && !(isHandset$ | async)"
    [disableClose]="true"
  >
    <app-sidebar-menu
      [company]="company$ | async"
      (changeProfile)="toggleProfile()"
      (showNotifications)="sidenavNotifications.toggle()"
    ></app-sidebar-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-sidenav-container class="full-height" hasBackdrop="false">
      <mat-sidenav
        #sidenavSubmenu
        class="app__submenu"
        [opened]="sidebarService.subMenuHoverOpened"
        (closedStart)="sidebarService.submenuClose()"
      >
        <app-sidebar-submenu></app-sidebar-submenu>
      </mat-sidenav>
      <mat-sidenav-content>
        <mat-sidenav-container
          class="full-height notifications-menu"
          (backdropClick)="sidebarService.setSpecificItem()"
        >
          <mat-sidenav #sidenavNotifications class="notifications-menu__content">
            <button
              mat-flat-button
              mat-icon-button
              class="close"
              color="primary"
              (click)="closeNotifications()"
            >
              <mat-icon>close</mat-icon>
            </button>
            <app-notifications-list (close)="closeNotifications()">
            </app-notifications-list>
          </mat-sidenav>
          <mat-sidenav-content>
            <div class="d-flex flex-column app__base">
              <app-notification-popup
                [visibility]="notificationPopup"
                (openNotifications)="sidenavNotifications.toggle()"
              ></app-notification-popup>
              <div class="router-container">
                <router-outlet (activate)="onActivate()"></router-outlet>
              </div>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </mat-sidenav-content>
    </mat-sidenav-container>
    <div
      class="w-100 h-100 loading"
      *ngIf="loading"
    >
      <mat-progress-bar
        color="primary"
        mode="indeterminate"
      ></mat-progress-bar>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
