<div class="file-comparison-info">
  <div class="font-size-16 font-weight-700 mb-16">Validação</div>

  <div class="font-size-14 font-weight-400 mb-16">Compare os dois documentos e responda os itens abaixo para saber a recomendação</div>

  <div [formGroup]="answers" class="answers">
    <div class="question-row">
      <div class="question-text">O pdf original possui assinatura digital válida?</div>
      <mat-button-toggle-group formControlName="validDigitalSignature" name="validDigitalSignature">
        <mat-button-toggle [value]="true" disabled>Sim</mat-button-toggle>
        <mat-button-toggle [value]="false" disabled>Não</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="question-row">
      <div class="question-text">O nome do funcionário é igual?</div>
      <mat-button-toggle-group formControlName="sameName" name="sameName">
        <mat-button-toggle [value]="true">Sim</mat-button-toggle>
        <mat-button-toggle [value]="false">Não</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="question-row">
      <div class="question-text">Os dados do emissor são iguais?</div>
      <mat-button-toggle-group formControlName="sameIssuerData" name="sameIssuerData">
        <mat-button-toggle [value]="true">Sim</mat-button-toggle>
        <mat-button-toggle [value]="false">Não</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="question-row">
      <div class="question-text">O tempo de afastamento é igual?</div>
      <mat-button-toggle-group formControlName="sameLeaveDuration" name="sameLeaveDuration">
        <mat-button-toggle [value]="true">Sim</mat-button-toggle>
        <mat-button-toggle [value]="false">Não</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="question-row">
      <div class="question-text">O tipo exige assinatura digital?</div>
      <mat-button-toggle-group formControlName="requiredDigitalSignature" name="requiredDigitalSignature">
        <mat-button-toggle [value]="true">Sim</mat-button-toggle>
        <mat-button-toggle [value]="false">Não</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div *ngIf="requirementsAreMet == null; else answerIsComputed" class="requirements-unknown font-size-14 font-weight-500">
    Recomendação
  </div>

  <ng-template #answerIsComputed>
    <div *ngIf="requirementsAreMet" class="requirements-are-met font-size-14 font-weight-500">
      <mat-icon>check</mat-icon>
      <div class="alert-message">O documento atende a todos os requisitos de validação</div>
    </div>

    <div *ngIf="!requirementsAreMet" class="requirements-are-not-met font-size-14 font-weight-500">
      <mat-icon>close</mat-icon>
      <div class="alert-message">O documento não atende a todos os requisitos de validação</div>
    </div>
  </ng-template>
</div>

<div class="file-comparison-item original-file">
  <span class="font-size-16 font-weight-700">PDF original</span>
  <app-file-preview
    class="file-wrapper"
    [fileSrc]="downloadedFile.url"
    [fileName]="downloadedFile.name"
    [fileType]="downloadedFile.type"
    [showQrCodeButton]="false"
    [status]="status"
  ></app-file-preview>
</div>

<div class="file-comparison-item">
  <span class="font-size-16 font-weight-700">Enviado pelo funcionário</span>
  <app-file-preview
    class="file-wrapper"
    [fileSrc]="originalFile.url"
    [fileName]="originalFile.name"
    [fileType]="originalFile.type"
    [showQrCodeButton]="false"
    [showSignaturePopover]="false"
  ></app-file-preview>
</div>

<button mat-icon-button class="mat-icon-button" color="primary" (click)="close()">
  <mat-icon class="mat-icon">close</mat-icon>
</button>
