import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, map, mergeMap, Observable, take } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { SessionService } from '../core/session.service';
import { CompanyService } from '../services/company.service';
import { Company } from '../shared/model/company';
import { CompanyItem } from '../components/companies-list/companies-list.component';

@Component({
  selector: 'app-company-picker',
  templateUrl: './company-picker.component.html',
  styleUrls: ['./company-picker.component.scss']
})
export class CompanyPickerComponent implements OnInit {
  companies$: Observable<CompanyItem[]>;
  selectingCompany = false;

  private returnUrl: string;
  private regex = /c\/([\w-]+)/;

  constructor(
    public sessionService: SessionService,
    public companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private authService: AuthService,
  ) {
    this.companies$ = this.companyService.companies$.pipe(
      mergeMap((companies) => {
        const observables = companies.map((company) => {
          return this.authService.isLoggedIn(company.id).pipe(
            map((loggedIn) => ({ loggedIn, company })),
            take(1) // isLoggedIn obs never completes. Must use take() to complete.
          );
        });
        return forkJoin(observables);
      })
    );
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/c/default';
  }

  chooseCompany(company: Company) {
    this.selectingCompany = true;
    this.sessionService.changeCurrentCompany(company.id)
      .subscribe({
        next: () => {
          const url = this.returnUrl.replace(this.regex, `c/${company.id}`);
          this.router.navigateByUrl(url);
        },
        complete: () => {
          this.selectingCompany = false;
        },
      });
  }

  goBack() {
    this.location.back();
  }
}
