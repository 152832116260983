import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  constructor(private apiService: ApiService) {}

  findQrCode(bucket: string, path: string): Observable<QrCodeResponse> {
    return this.apiService.get<QrCodeResponse>(`/bff/v1/qrcode/find?bucket=${bucket}&path=${path}`);
  }

  downloadPdf(url: string, secret: string): Observable<PdfResponse> {
    return this.apiService.post<PdfResponse>('/bff/v1/qrcode/download_url', {
      url,
      secret,
    });
  }
}

export class QrCodeResponse {
  link: string;
  bounding_box: {
    height: number;
    width: number;
    top: number;
    left: number;
  }
}

export class PdfResponse {
  version: string;
  prescription: {
    signatureFiles: {
      url: string;
    }[];
  }
}
