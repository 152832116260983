import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { getAuth, SAMLAuthProvider, signInWithCustomToken, signInWithRedirect, UserCredential } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { forkJoin, from, lastValueFrom, Observable, of } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AngularFireAuthFactory } from '../firebase.factory';
import { LocalStorageKeys, LocalStorageService } from '../services/local-storage.service';
import { shouldLogout } from '../shared/utils/auth.utils';

export const ERROR_CODES = {
  'auth/invalid-argument': 'Erro: Um argumento inválido foi fornecido.',
  'auth/invalid-disabled-field': 'Erro: O valor fornecido para a propriedade de usuário é inválido.',
  'auth/wrong-password': 'Erro: Usuário ou senha inválidos',
  'auth/admin-restricted-operation': 'Erro: Usuário ainda não cadastrado. Procure o RH da empresa para criar uma conta.',
  'auth/invalid-action-code': 'Esse link de login já expirou ou já foi utilizado. Continue para solicitar um novo link.',
  'auth/internal-error': 'Erro: Não foi possível acessar. Tente novamente.',
  'USER_NOT_FOUND': 'Erro: Email não cadastrado. Procure o RH da sua empresa para solicitar seu cadastro.',
  'RECAPTCHA_FAIL': 'Erro ao validar. Por favor, tente novamente ou fale com o suporte no botão "Ajuda"'
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    @Inject('angularFireAuthFactory') private angularFireAuthFactory: AngularFireAuthFactory,
    private localStorageService: LocalStorageService,
  ) {}

  getToken(companyId?: string): Observable<string> {
    return this.getInstance(companyId).idToken;
  }

  getUser(companyId?: string): Observable<firebase.User> {
    return this.getInstance(companyId).user;
  }

  isLoggedIn(companyId?: string): Observable<boolean> {
    return this.getUser(companyId).pipe(
      map((user) => !!user && !user.isAnonymous)
    );
  }

  getInstance(companyId?: string): AngularFireAuth {
    return this.angularFireAuthFactory.getInstance(companyId);
  }

  emailPasswordLogin(email: string, password: string): Observable<firebase.auth.UserCredential> {
    return from(this.angularFireAuth.signInWithEmailAndPassword(email, password));
  }

  emailLinkComplete(email: string, emailLink: string) {
    // The client SDK will parse the code from the link for you.
    return from(this.angularFireAuth.signInWithEmailLink(email, emailLink))
      .pipe(
        tap(() => window.localStorage.removeItem('emailForSignIn')),
      );
  }

  verifyPasswordResetCode(actionCode): Observable<string> {
    return from(this.angularFireAuth.verifyPasswordResetCode(actionCode));
  }

  resetPassword(actionCode: string, newPassword: string): Observable<void> {
    return from(this.angularFireAuth.confirmPasswordReset(actionCode, newPassword));
  }

  initializeAndSaveApp(companyId: string): FirebaseApp {
    this.localStorageService.addItemToArray(LocalStorageKeys.FIREBASE_APP_INSTANCES_NAMES, companyId);
    return initializeApp(environment.firebase, companyId);
  }

  signInWithRedirect(companyId: string, tenantId: string, providerId: string): Observable<void> {
    let app: FirebaseApp;
    if (companyId) {
      app = this.initializeAndSaveApp(companyId);
      localStorage.setItem('SAML_LOGIN_COMPANY_ID', companyId);
    } else {
      app = getApp();
    }
    const auth = getAuth(app);
    auth.tenantId = tenantId;
    return from(signInWithRedirect(auth, new SAMLAuthProvider(providerId)));
  }

  signInWithCustomToken(companyId: string, tenantId: string, token: string): Observable<UserCredential> {
    const app = this.initializeAndSaveApp(companyId);
    const auth = getAuth(app);
    auth.tenantId = tenantId;
    return from(signInWithCustomToken(auth, token));
  }

  isLoggedInIntoThePlatform(): Observable<boolean> {
    return this.isLoggedIn(null).pipe(
      mergeMap((isLoggedInOnDefaultInstance) => {
        if (isLoggedInOnDefaultInstance) {
          return of(true);
        }

        const names = this.getFirebaseInstanceNames();
        if (!names || names.length === 0) {
          return of(false);
        }

        const results: Observable<boolean>[] = names.map((name) => this.isLoggedIn(name).pipe(take(1)));
        return forkJoin(results).pipe(
          map((result: boolean[]) => result.some(value => value === true)),
        )
      })
    )
  }

  getApp(name: string): FirebaseApp {
    let app: FirebaseApp;
    try {
      app = getApp(name);
    } catch {
      app = initializeApp(environment.firebase, name);
    }
    return app;
  }

  async logoutSingleInstance(name: string): Promise<void> {
    const app = this.getApp(name);
    return getAuth(app).signOut();
  }

  async partialLogout(): Promise<void> {
    const names = this.getFirebaseInstanceNames();
    names.push(null); //default instance
    // Here we call 'signOut' only for instances that has expired tokens
    const promises = names.map(
      (name) => lastValueFrom(this.getUser(name).pipe(take(1))).then(
        async (user) => {
          if (!user) {
            return Promise.resolve();
          }
          const token = await user.getIdTokenResult();
          if (shouldLogout(token, new Date(user.metadata.lastSignInTime))) {
            return this.logoutSingleInstance(name);
          }
          return await Promise.resolve();
        }
      )
    );
    await Promise.all(promises);
  }

  async totalLogout(): Promise<void> {
    const names = this.getFirebaseInstanceNames();
    // Here we call 'signOut' for every instance
    const promises = names.map((name) => this.logoutSingleInstance(name));
    await Promise.all(promises);
    await getAuth(this.getApp(null)).signOut();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  public errorByCode(code: string): string {
    if (ERROR_CODES[code]) {
      return (ERROR_CODES[code]);
    } else {
      return ('Ocorreu um erro! Tente novamente ou fale com o suporte pelo botão de ajuda.' + (code ? ' Codigo: ' + code : ''));
    }
  }

  private getFirebaseInstanceNames(): string[] {
    return this.localStorageService.getArray(LocalStorageKeys.FIREBASE_APP_INSTANCES_NAMES);
  }
}
