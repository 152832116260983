<div class="company-content">
  <div
    class="company-content__action"
  >
    <div
      class="company-content__logo"
      *ngIf="company.logo_url"
    >
      <img
        class="company-content__logo-image"
        [src]="company.logo_url"
        [alt]="company.name"
      >
    </div>
    <span
      class="single-line"
      *ngIf="!company.logo_url"
    >{{ company.name }}</span>
    <mat-icon
      class="company-content__arrow"
    >chevron_right</mat-icon>
  </div>
  <div
    class="company-content__tip"
    [tabindex]="tabIndex"
  >Trocar de conta</div>
</div>
