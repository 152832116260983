import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { FilePreviewModule } from '../file-preview/file-preview.module';
import { FileComparisonDialogComponent } from './file-comparison-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    FileComparisonDialogComponent
  ],
  exports: [
    FileComparisonDialogComponent
  ],
  imports: [
    CommonModule,
    FilePreviewModule,
    MatLegacyProgressSpinnerModule,
    MatIconModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
  ]
})
export class FileComparisonDialogModule { }
