import {NgModule} from '@angular/core';

import {ImagePreviewComponent} from './image-preview.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {DragScrollModule} from 'ngx-drag-scroll';
import {NgxViewerModule} from '../ngx-viewer/ngx-viewer.module';

@NgModule({
  declarations: [ ImagePreviewComponent ],
  imports: [
    MatButtonModule,
    CommonModule,
    SharedModule,
    MatIconModule,
    DragScrollModule,
    NgxViewerModule
  ],
  exports: [ ImagePreviewComponent ]
})
export class ImagePreviewModule {

}
