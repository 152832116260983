<ng-container *ngIf="!loading">
  <h2 mat-dialog-title class="position-relative" [ngStyle]="data.titleStyle">
    {{data.title}}
    <button mat-icon-button mat-dialog-close class="small-circle-button">
      <mat-icon>close</mat-icon>
    </button>
  </h2>

  <div class="small-form-field">
    <div [ngStyle]="data.contentStyle">{{data.content}}</div>
  </div>

  <mat-dialog-actions align="end">
    <button mat-button type="button" [color]="!!data?.color ? data.color : 'primary'" *ngIf="!!data.noButton" (click)="onNoClicked()" cdkFocusInitial>
      {{ data.noButton }}
    </button>
    <button mat-button type="button" [color]="!!data?.color ? data.color : 'primary'" *ngIf="!!data.yesButton" (click)="onOkClick()">
      {{ data.yesButton }}
    </button>
  </mat-dialog-actions>
</ng-container>

<div *ngIf="loading" class="validation-retry-dialog-loading">
  <mat-spinner diameter="32" color="primary"></mat-spinner>
</div>
