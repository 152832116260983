import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import {
  ApiService,
  GET_COMPANY_DETAILS,
  LIST_COMPANIES
} from 'src/app/core/api.service';
import { AuthService } from '../core/auth.service';
import { take } from 'rxjs/operators';

export class Provider {
  auth_tenant_id: string;
  domains: string[];
  expiration_interval: number;
  id: string;
  type: string;
}

export class Company {
  id: string;
  name: string;
  organization_id: number;
  providers: Provider[];
}

export class CompanyList {
  items: Company[];
}

@Injectable({
  providedIn: 'root'
})
export class CompanyV2Service {
  constructor(
    private api: ApiService,
    private authService: AuthService,
  ) {}

  listCompanies(): Observable<CompanyList> {
    return this.api.get<CompanyList>(LIST_COMPANIES);
  }

  getCompanyDetails(companyId: string): Observable<Company> {
    return this.authService.getToken(companyId).pipe(
      take(1),
      mergeMap((token) => this.api.get<Company>(GET_COMPANY_DETAILS(companyId), { headers: { 'Authorization': `Bearer ${token}` }})),
    );
  }
}
