import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import { environment } from 'src/environments/environment';

export const CLOSECARE_API_BASE_PATH = (version, companyId: string) => `/${version}/companies/${companyId}`;
export const METABASE_CF_API =
  (companyId: string, dashboardId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/dashboards/${dashboardId}`;

export const PEOPLE_URL = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v3', companyId)}/people`;
export const SEARCH_PEOPLE_URL = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v3', companyId)}/search/people`;

export const USER_URL = (companyId: string, userId?: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/users${!!userId ? '/' + userId : ''}`;
export const USER_JWT = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/users/profile`;

export const SICK_NOTE_API_URL = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/sick_notes`;
export const SICK_NOTE_API_URL_V5 = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v5', companyId)}/sick_notes`;
export const ASSIGN_SN_API_URL = (companyId: string, sickNoteId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/sick_notes/${sickNoteId}/assign`;
export const VERIFY_SN_API_URL = (companyId: string, sickNoteId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/sick_notes/${sickNoteId}/verify`;

export const SITUATIONS_API_URL = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/situations`;
export const SICK_NOTE_TYPES_LIST = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v3', companyId)}/sick_notes_types`;
export const SICK_NOTE_TYPES_SAVE = (companyId: string, sickNoteTypeId?: number) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/sick_notes_types${!!sickNoteTypeId ? ('/' + sickNoteTypeId) : ''}`;
export const SICK_NOTE_IMAGES_API_URL = (companyId: string, sickNoteId: string) => `${CLOSECARE_API_BASE_PATH('v3', companyId)}/sick_notes/${sickNoteId}/images`;
export const WARNINGS_API_URL = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v3', companyId)}/warnings`;
export const ICD_SEARCH_API = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v3', companyId)}/search/icds`;
export const COMPANY_DEPARTMENTS_API = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v3', companyId)}/departments`;
export const COMPANY_LOCATIONS_API = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v3', companyId)}/locations`;
export const GET_COMPANY_DETAILS = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}`;

export const PEOPLE_FILE_UPLOAD = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v3', companyId)}/import/file`;

export const SICK_NOTE_PERSON_WARNINGS_API_URL = (companyId: string, id: string) => `${CLOSECARE_API_BASE_PATH('v3', companyId)}/people/${id}/warnings`;

export const NOTIFICATIONS_USERS_LIST_API_URL = (companyId: string, notificationId: string, userId?: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/notifications/${notificationId}/users${userId ? '/' + userId : ''}`;

export const MAGICLINK_API_URL = 'v4/magicLink';
export const USER_PERMISSIONS_API = (type: 'company' | 'tenant', id: string) => `/v3/permissions/${type}/${id}`;
export const CHARDET_URL = `/v3/utils/file/chardet`;
export const API_USER_HMAC = (app) => `/v3/utils/hmac/${app}`;
export const API_PROFESSIONALS = (id, state) => `/v4/professionals/${id}/${state}`;
export const API_REASONS_MESSAGES = (companyId, reasonId?) => (
  `/v4/companies/${companyId}/reason_messages${!!reasonId ? ('/' + reasonId) : ''}`
);
export const LIST_COMPANIES = '/v5/companies';
export const ROLES = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v3', companyId)}/roles`;
export const LOCATIONS = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/locations`;
export const API_LOG_SYNC = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/sync/sick_notes`;
export const DASHBOARDS = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/dashboards`;
export const UPLOADS = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/uploads`;
export const COMPANY_SETTINGS = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/settings`;
export const TEAMS = (companyId: string) => `${CLOSECARE_API_BASE_PATH('v4', companyId)}/teams`;
export const USER_PROFILE = '/v4/profile';
export const PROFESSIONAL_TYPES = '/v4/professional_types';
export const PERMISSIONS = '/v4/auth/permissions';
export const VACCINE_TYPES = 'v3/utils/vaccines';
export const DOCUMENT_TYPES = 'v4/document_types';
export const CATEGORIES = 'v4/categories';
export const RAIS_REASONS = 'v4/reasons/rais';
export const CUSTOM_TOKEN_API = (organizationId: number) => `/bff/v1/organizations/${organizationId}/custom-token?key=${environment.firebase.apiKey}`
export const SIGNATURES_VALIDATION_EXTERNAL = '/bff/v1/signatures/external';

export class ListAPIResponse<T = any> {
  num_pages?: number;
  page?: number;
  total?: number;
  objects_per_page?: number;
  items: T[];
}

export type IPagitationSource = Omit<
  ListAPIResponse, 'items'
>;

export interface GetOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor (
    private http: HttpClient,
    private logger: NGXLogger) {
  }

  get<T> (url: string, options?: GetOptions): Observable<T> {
    return this.http.get<T>(url, options);
  }

  post<T> (url: string, body: any | null, options?: {headers?: HttpHeaders, params?: HttpParams}): Observable<T> {
    return this.http.post<T>(url, body, options);
  }

  put<T> (url: string, body: any | null, options?: {headers?: HttpHeaders, params?: HttpParams}): Observable<T> {
    return this.http.put<T>(url, body, options);
  }

  patch<T> (url: string, body: any | null, options?: {headers?: HttpHeaders, params?: HttpParams}): Observable<T> {
    return this.http.patch<T>(url, body, options);
  }

  delete<T> (url: string, options?: any): Observable<any> {
    return this.http.delete(url, options);
  }
}
