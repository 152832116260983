import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {CompanyService} from '../services/company.service';
import {map, take, tap} from 'rxjs/operators';
import {NotificationService} from '../services/notification.service';
import {AuthService} from './auth.service';
import {UserService} from '../services/user.service';
import {SessionService} from './session.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(private router: Router,
              private session: SessionService,
              private notificationService: NotificationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const needed = next.data.permissions as string[];
    const allowed = needed.every(p => this.session.permissions.permissions[p]);
    if (!allowed) {
      this.notificationService.messagesSubj.next('Acesso não permitido');
      this.router.createUrlTree(['/companies'])
    }
    return allowed;
  }
}
