<ng-container>
  <div class="popover-container"
    (click)="openPopover()"
    [ngClass]="{
      'open': open,
      'closed': !open,
      'valid': status === SignatureStatus.VALID,
      'invalid': status !== SignatureStatus.VALID
  }">
    <mat-icon class="popover-icon">
        <ng-container *ngIf="status === SignatureStatus.VALID">check_circle</ng-container>
        <ng-container *ngIf="status !== SignatureStatus.VALID">warning</ng-container>
    </mat-icon>
    <div class="popover-text">
        <ng-container *ngIf="status === SignatureStatus.VALID">
          <div class="font-size-14 font-weight-500">Assinatura digital válida</div>
        </ng-container>
        <ng-container *ngIf="status !== SignatureStatus.VALID">
          <div class="font-size-14 font-weight-500">Assinatura digital inválida/ausente</div>
          <div class="font-size-13 font-weight-400">Verifique o QR code ou a assinatura física</div>
        </ng-container>
    </div>
    <mat-icon class="popover-close" (click)="closePopover($event)">close</mat-icon>
  </div>
</ng-container>
