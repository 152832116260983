<div class="w-100 d-flex flex-column" (mouseenter)="focused = true" (mouseleave)="focused = false">
  <div class="d-flex flex-grow-1 limit">
    <div *ngIf="loading" class="loading d-flex justify-content-center align-items-center">
      <mat-progress-spinner mode="indeterminate" diameter="32"></mat-progress-spinner>
    </div>
    <app-signature-popover *ngIf="!loading && showSignaturePopover && status !== undefined" class="signature-popover" [status]="status"></app-signature-popover>
    <div
      class="d-flex w-100 h-100"
      *ngIf="!!fileContents"
    >
      <pdf-viewer
        class="flex-grow-1"
        *ngIf="fileType === 'application/pdf'; else image"
        [src]="fileContents"
        [render-text]="true"
        [original-size]="false"
        [zoom-scale]="'page-fit'"
        [show-all]="true"
        [page]="1"
        [zoom]="zoom"
        [rotation]="rotation"
        (after-load-complete)="loading = false"
        (error)="errorOnLoad($event)"
      >
      </pdf-viewer>
      <ng-template #image>
        <app-image-preview
          #image
          [fileSrc]="fileContents?.url"
          [fileData]="fileContents?.data"
          [zoom]="zoom"
          [rotation]="rotation"
          (loaded)="loading = false"
        ></app-image-preview>
      </ng-template>
      <div class="error-message" *ngIf="!!errorMessage">{{ errorMessage }}</div>
    </div>
  </div>
  <div class="d-flex justify-content-center action-wrapper">
    <div class="action-buttons my-3" [ngClass]="{ 'action-buttons--focused': focused }">
      <!-- <button [@save] *ngIf="rotated" (click)="save()" class="p-2 action-button action-button-save">Salvar</button> -->
      <div
        class="action-button-border action-count"
        *ngIf="!!fileControl?.length"
      >
        Imagem {{ fileControl.current }}/{{ fileControl.length }}
      </div>
      <button
        mat-icon-button
        class="p-2 action-button action-button-border"
        matTooltip="Ler QR Code"
        matTooltipPosition="above"
        (click)="scanQrCode()"
        *ngIf="showQrCodeButton"
        [disabled]="qrCodeDialogIsOpen"
      >
        <mat-icon>qr_code_scanner</mat-icon>
      </button>
      <button
        mat-icon-button
        class="p-2 action-button action-button-border"
        matTooltip="Baixar imagem"
        matTooltipPosition="above"
        (click)="download()"
      >
        <mat-icon>file_download</mat-icon>
      </button>
      <button
        mat-icon-button
        class="p-2 action-button action-button-border"
        color="accent"
        matTooltip="Apagar imagem"
        matTooltipPosition="above"
        *ngIf="showDelete"
        (click)="delete.emit(fileControl.current - 1)"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <button
        mat-icon-button
        class="p-2 action-button action-button-border"
        matTooltip="Girar imagem"
        matTooltipPosition="above"
        (click)="rotate()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
      <button
        mat-icon-button
        class="p-2 action-button"
        matTooltip="Reduzir zoom"
        matTooltipPosition="above"
        (click)="zoomOut()"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <button
        mat-icon-button
        class="p-2 action-button"
        matTooltip="Aumentar zoom"
        matTooltipPosition="above"
        (click)="zoomIn()"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        class="p-2 action-button"
        matTooltip="Resetar zoom"
        matTooltipPosition="above"
        (click)="defaultZoom()"
      >
        <mat-icon>crop_free</mat-icon>
      </button>
    </div>
  </div>
</div>
