<aside class="overflow-hidden menu">
  <a
    class="menu__header"
    routerLink="/c/{{company?.id}}"
    tabindex="1"
  >
    <img
      class="menu__logo"
      src="assets/img/closecare.png"
      alt="closecare"
    >
  </a>
  <div class="main__scroll menu__scroll">
    <div class="menu__list">
      <ng-container *ngIf="company">
        <button
          class="menu__button"
          routerLinkActive="menu__button--selected"
          routerLink="/companies"
          tabindex="2"
          [routerLinkActiveOptions]="{ exact: true }"
          [attr.aria-label]="company.name"
        >
          <app-company-content
            [company]="company"
            [tabIndex]="3"
          ></app-company-content>
        </button>
      </ng-container>
      <ng-container *ngFor="let item of topItems; let i = index">
        <ng-container *ngIf="company && item.allowed">
          <app-item-button
            class="d-block"
            [item]="item"
            [companyId]="companyId"
            [tabIndex]="sequentialIndex + i"
          ></app-item-button>
        </ng-container>
      </ng-container>
    </div>
    <div class="menu__list">
      <ng-container *ngFor="let item of bottomItems; let i = index">
        <ng-container *ngIf="company && item.allowed">
          <app-item-button
            class="d-block"
            [item]="item"
            [companyId]="companyId"
            [tabIndex]="sequentialIndex + topItems?.length + i"
          ></app-item-button>
        </ng-container>
      </ng-container>
      <button
        class="menu__button"
        [ngClass]="{
          'menu__button--selected':
            sidebarService.specificItem === specificsItems.notification
        }"
        [attr.aria-label]="'Notificações'"
        [tabindex]="sequentialIndex + topItems?.length + bottomItems?.length + 1"
        (click)="selectNotifications($event)"
      >
        <app-item-content
          class="menu__item-content"
          icon="notifications"
          title="Notificações"
          [notifications]="(notificationService?.notifications$ | async)?.length"
        ></app-item-content>
      </button>
      <button
        class="menu__button menu__item-content"
        matTooltip="{{user?.email}}"
        *ngIf="sessionService.loggedInUser$ | async as user"
        [attr.aria-label]="user?.email"
        [ngClass]="{
          'menu__button--selected':
            sidebarService.specificItem === specificsItems.profile
        }"
        [tabindex]="sequentialIndex + topItems?.length + bottomItems?.length + 2"
        (click)="selectProfile($event)"
      >
        <div class="menu__avatar circle">
          {{(user?.name || '') | nameInitials}}
        </div>
        <div class="single-line menu__button-text">
          {{user?.name}}
        </div>
      </button>
    </div>
  </div>
  <button
    class="
      mx-0
      mb-0
      menu__button
      menu__button--help
      menu__item-content
    "
    aria-label="Suporte"
    id="chatButton"
    [tabindex]="sequentialIndex + topItems?.length + bottomItems?.length + 3"
  >
    <mat-icon class="menu__icon">chat_bubble_outline</mat-icon>
    <div class="menu__button-text">
      Suporte
    </div>
  </button>
  <span class="font-size-10 color-neutral-60 py-1 menu__version">
    versão {{ appVersion }}
  </span>
</aside>
