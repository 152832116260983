import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

export interface StatefulLoadingDialogData {
  title: string;
  content: string;
  noSpinner?: boolean;
  action?: {
    label: string;
  };
}

@Component({
  selector: 'app-stateful-loading',
  templateUrl: './stateful-loading.component.html',
  styleUrls: ['./stateful-loading.component.scss']
})
export class StatefulLoadingComponent implements OnInit {

  public static open(dialog: MatDialog, data: StatefulLoadingDialogData, config?: MatDialogConfig)
    : MatDialogRef<StatefulLoadingComponent, boolean> {
      const c: MatDialogConfig = config || {};
      c.panelClass = 'simple-dialog';
      c.maxWidth = 'calc(100vw - 32px)';
      c.data = data;
      return dialog.open(StatefulLoadingComponent, c);
  }

  constructor(public dialogRef: MatDialogRef<StatefulLoadingComponent>,
              @Inject(MAT_DIALOG_DATA) public data: StatefulLoadingDialogData) { }

  ngOnInit(): void {
  }

  actionClicked() {
    this.dialogRef.close(false);
  }
}
