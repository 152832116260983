import {Component, EventEmitter, Input, Output} from '@angular/core';
import { Company } from 'src/app/shared/model/company';

export interface CompanyItem {
  loggedIn: boolean;
  company: Company;
}

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent {
  @Input()
  companies: CompanyItem[];

  @Output()
  chooseCompany = new EventEmitter<Company>();

  onChooseCompany(company: Company) {
    this.chooseCompany.emit(company);
  }
}
