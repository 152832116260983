import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SIGNATURES_VALIDATION_EXTERNAL } from "../core/api.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SignaturesService {
    constructor(private httpClient: HttpClient) {}

    validade(url: string): Observable<ValidationResponse> {
        return this.httpClient.post<ValidationResponse>(SIGNATURES_VALIDATION_EXTERNAL, { url, });
    }
}

export class ValidationResponse {
  status: string;
}
