import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  combineLatest,
  Observable,
  of,
  Subject
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  retry,
  shareReplay,
  take
} from 'rxjs/operators';

import {
  ApiService,
  COMPANY_DEPARTMENTS_API,
  COMPANY_LOCATIONS_API,
  COMPANY_SETTINGS,
  IPagitationSource,
  LIST_COMPANIES,
  ListAPIResponse,
  LOCATIONS,
  ROLES,
  TEAMS
} from 'src/app/core/api.service';
import { SessionService } from 'src/app/core/session.service';
import { Department } from 'src/app/shared/interfaces/core.interface';
import {
  IV3LocationResponse,
  IV4LocationGroup
} from 'src/app/shared/interfaces/location.interface';
import {
  Company,
  CompanySettings
} from 'src/app/shared/model/company';
import { Role } from 'src/app/shared/model/role';
import { Team } from 'src/app/shared/model/team';

export type IResponseDepartments = IPagitationSource & {
  departments: Department[];
};

export interface IQueryParams {
  page?: number;
  query?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  readonly companies$: Observable<Company[]>;
  readonly currentCompany$: Observable<Company>;
  readonly settings$: Observable<CompanySettings>;
  readonly roles$: Observable<Role[]>;

  private teams$: Observable<Team[]>;
  private departments$: Observable<ListAPIResponse<Department>>;
  private locationsV4$: Observable<IV4LocationGroup[]>;
  private readonly refreshRoles$ = new Subject<void>();

  loadingCompanies: boolean = false;

  constructor(private session: SessionService,
              private api: ApiService) {
    this.currentCompany$ = this.session.currentCompanyId$.pipe(
      mergeMap(company_id => this.getCompanyDetails(company_id)),
      shareReplay(1)
    );

    this.settings$ = this.session.currentCompanyId$.pipe(
      mergeMap(companyId => this.getSettings(companyId)),
      shareReplay(1)
    );

    // Refresh companies if user changes
    this.companies$ = this.session.authUser$.pipe(
      filter(() => !this.session.currentCompanyId),
      distinctUntilChanged((previous, current) => previous?.uid === current?.uid),
      mergeMap(user => {
        if (user) {
          return this.getCompanies();
        } else {
          return of([]);
        }
      }),
      catchError(err => []),
      shareReplay(1)
    );

    this.roles$ = combineLatest([
      this.refreshRoles$,
      this.session.currentCompanyId$
    ]).pipe(
      mergeMap(([refresh, companyId]: any[]) => this.api.get<any>(ROLES(companyId))),
      map(({ items }) => items),
      shareReplay(1)
    );

    this.refreshRoles();
  }

  getCompanyDetails(company_id: string): Observable<Company> {
    if (!company_id || !this.companies$) {
      return of(null);
    }
    return this.companies$.pipe(take(1), map((companies) => companies.find((c) => c.id === company_id)));
  }

  getLocations(page = 1, pageSize = 500, query?: string): Observable<IV3LocationResponse> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('obj_per_page', pageSize.toString());

    if (query) {
      params = params.set('query', query);
    }

    return this.session.currentCompanyId$.pipe(
      mergeMap(company_id => this.api.get<IV3LocationResponse>(COMPANY_LOCATIONS_API(company_id), {params})),
      shareReplay(1)
    );
  }

  getLocationsV4(query?: string): Observable<IV4LocationGroup[]> {
    if (!this.locationsV4$) {
      let params = new HttpParams()
        .set('page', '1')
        .set('obj_per_page', '2000');

      if (query) {
        params = params.set('query', query);
      }

      this.locationsV4$ = this.session.currentCompanyId$.pipe(
        mergeMap(company_id => this.api.get(
            LOCATIONS(company_id), {params}
          )
          .pipe(map((
            { location_groups }: { location_groups: IV4LocationGroup[] }
          ) => location_groups.map((group: IV4LocationGroup) => {
              if (group.id === null) {
                group.id = 0;
                group.name = 'Sem região';
              }

              return group;
            })))),
        shareReplay(1)
      );
    }

    return this.locationsV4$;
  }

  getDepartments(queryParams: IQueryParams): Observable<ListAPIResponse<Department>> {
    const params = new HttpParams()
      .set('obj_per_page', '20')
      .set('page', queryParams.page?.toString() || '1')
      .set('query', queryParams.query || '');

    this.departments$ = this.session.currentCompanyId$.pipe(
      mergeMap(company_id => (
        this.api
          .get(COMPANY_DEPARTMENTS_API(company_id), { params })
          .pipe(take(1))
      )),
      shareReplay(1),
      map((response: IResponseDepartments) => ({
        num_pages: response.num_pages,
        page: response.page,
        total: response.total,
        items: response.departments
      }))
    );

    return this.departments$;
  }

  getTeams(): Observable<Team[]> {
    if (!this.teams$) {
      this.teams$ = this.session.currentCompanyId$
        .pipe(
          mergeMap(companyId => {
            if (companyId) {
              return this.api.get<{ items: Team[] }>(TEAMS(companyId));
            }
            return of({items: []});
          }),
          map((response) => response.items),
          map((teams) => {
            teams.unshift({
              id: null,
              label: 'Não atribuído',
              code: ''
            });
            return teams;
          }),
          shareReplay(1)
        );
    }

    return this.teams$.pipe(shareReplay(1));
  }

  refreshRoles(): void {
    this.refreshRoles$.next();
  }

  private getCompanies(sort = true): Observable<Company[]> {
    this.loadingCompanies = true;
    return this.api.get<{items: Company[]}>(LIST_COMPANIES).pipe(
      retry(3),
      map((resp) => {
        this.loadingCompanies = false;
        if (sort) {
          return resp.items.sort((a, b) => ('' + a.name).localeCompare(b.name));
        }
        return resp.items;
      }),
      catchError((error) => {
        this.loadingCompanies = false;
        throw error;
      })
    );
  }

  private getSettings(companyId: string): Observable<CompanySettings> {
    return this.api.get<CompanySettings>(COMPANY_SETTINGS(companyId));
  }
}
