import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { SessionService } from "../core/session.service";

@Injectable({ providedIn: 'root' })
export class CompanyPickerGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.sessionService.currentCompanyId) {
      return this.sessionService.changeCurrentCompany(null);
    }
    return true;
  }
}
