import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService } from '../core/session.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuard implements CanActivate {

  constructor(
    private router: Router,
    private sessionService: SessionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const companyId = route.paramMap.get('company_id');
    return this.sessionService.changeCurrentCompany(companyId).pipe(
      map((success) => success || this.router.createUrlTree(['login'], {queryParams: {returnUrl: state.url}}))
    );
  }
}
