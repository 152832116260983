<ng-container *ngIf="companies.length > 0">
    <mat-action-list class="company-container">
        <ng-container *ngFor="let company of companies">
            <mat-list-item class="company-item" (click)="onChooseCompany(company.company)" [ngClass]="company.loggedIn ? 'logged-in' : 'logged-out'">
                <div class="company-first-letter">
                  {{ company.company.name[0] }}
                </div>
                <span class="company-name">{{ company.company.name }}</span>
                <button mat-button color="primary" *ngIf="company.loggedIn; else loginButton">
                  Selecionar
                  <mat-icon *ngIf="company.loggedIn">arrow_forward</mat-icon>
                </button>
              <ng-template #loginButton>
                <button mat-raised-button color="primary" >Entrar</button>
              </ng-template>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-action-list>
</ng-container>
