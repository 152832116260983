import { Component, Input } from '@angular/core';
import { SignatureStatus } from './signature-popover.entities';

@Component({
  selector: 'app-signature-popover',
  templateUrl: './signature-popover.component.html',
  styleUrls: ['./signature-popover.component.scss']
})
export class SignaturePopoverComponent {
  open = true;

  @Input()
  status: string | null;

  get SignatureStatus() {
    return SignatureStatus;
  }

  openPopover() {
    this.open = true;
  }

  closePopover(event: Event) {
    event.stopPropagation();
    this.open = false;
  }
}
