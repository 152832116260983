import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignaturePopoverComponent } from './signature-popover.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    SignaturePopoverComponent
  ],
  exports: [
    SignaturePopoverComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
  ]
})
export class SignaturePopoverModule { }
