import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { SessionService } from "../core/session.service";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SamlRedirectGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionService: SessionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    const companyIdSAML = localStorage.getItem('SAML_LOGIN_COMPANY_ID');
    if (companyIdSAML) {
      localStorage.removeItem('SAML_LOGIN_COMPANY_ID');
      return this.sessionService.changeCurrentCompany(companyIdSAML).pipe(
        map((success) => {
          if (success) {
            return this.router.createUrlTree([`/c/${companyIdSAML}`]);
          }
          return true;
        })
      )
    }
    return true;
  }
}
